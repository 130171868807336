"use client";

import Image from "next/image";
import React, { useCallback } from "react";

interface CarouselItemProps {
  height: number;
  width: number;
  shape?: string;
  image: string;
  video?: string;
  imageAlt: string;
  priority?: boolean;
}

const CarouselItem: React.FC<CarouselItemProps> = ({
  height = 512,
  width = 512,
  shape = "default",
  image,
  video,
  imageAlt = "",
  priority = false,
}) => {
  const handleMetadataLoaded = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement, Event>): void => {
      event.currentTarget.currentTime = 0;
    },
    [],
  );

  const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLVideoElement>): void => {
    void e.currentTarget.play();
  }, []);

  const handleMouseLeave = useCallback((e: React.MouseEvent<HTMLVideoElement>): void => {
    e.currentTarget.pause();
  }, []);

  if (video) {
    return (
      <video
        className={`carousel-video ${shape}`}
        data-shape={shape}
        height={height}
        poster={image}
        preload="auto"
        width={width}
        loop
        muted
        playsInline
        onLoadedMetadata={handleMetadataLoaded}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <source src={video} type="video/mp4" />
      </video>
    );
  }

  return (
    <Image
      alt={imageAlt}
      className={`carousel-image ${shape}`}
      data-shape={shape}
      height={height}
      priority={priority}
      src={image}
      width={width}
    />
  );
};

export default React.memo(CarouselItem);
