"use client";

import Link from "next/link";
import React, { useCallback, useState } from "react";

import CarouselItem from "@/components/content/carousel-item";
import { removeParagraphTags } from "@/utils/helpers";

interface CarouselItemData {
  id: string;
  title: string;
  description: string;
  href: string;
  shape?: string;
  image?: string;
  video?: string;
}

interface HomeCarouselProps {
  carouselItems: CarouselItemData[];
}

export default function HomeCarousel({ carouselItems }: HomeCarouselProps): React.JSX.Element {
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const handleMouseEnter = useCallback((id: string) => {
    setActiveItem(id);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveItem(null);
  }, []);

  const carouselItemDefaultSize = 512;

  return (
    <ul className="carousel">
      {carouselItems.map((item, index) => (
        <li key={item.id}>
          <span className="carousel-item-container">
            <Link
              aria-label={item.title}
              className="carousel-item-cell carousel-item-media"
              data-shape={item.shape || "default"}
              href={item.href}
              onMouseEnter={() => handleMouseEnter(item.id)}
              onMouseLeave={handleMouseLeave}
            >
              <CarouselItem
                height={carouselItemDefaultSize}
                image={item.image || ""}
                imageAlt=""
                priority={index === 0} // Add priority to the first item
                shape={item.shape}
                video={item.video}
                width={carouselItemDefaultSize}
              />
            </Link>
            <span className="carousel-item-cell carousel-item-header">
              <h2>
                <Link data-is-active={String(activeItem === item.id)} href={item.href}>
                  {item.title}
                </Link>
              </h2>
            </span>
            <span
              className="carousel-item-cell carousel-item-call-to-action"
              dangerouslySetInnerHTML={{ __html: removeParagraphTags(item.description) }}
            />
          </span>
        </li>
      ))}
    </ul>
  );
}
