export const parentWebsite = "https://aiml4pi.org";

export function normalizePath(path: string): string {
  // Function to remove trailing slash if present
  return path.replace(/\/$/, "");
}

export function formatNumber(num: number | string | null | undefined): string {
  if (typeof num === "number") return num.toLocaleString("en-US");
  if (typeof num === "string") {
    const parsed = parseFloat(num);
    return isNaN(parsed) ? "0" : parsed.toLocaleString("en-US");
  }
  return "0";
}

export const toCamelCase = (input: string): string =>
  input
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, "");

export const transformLabelToClassName = (label: string): string =>
  label
    .toLowerCase()
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/\s+/g, "-");

export const normalizeStringForComparison = (str: string): string => {
  return str
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .trim()
    .toLowerCase();
};

interface TruncateProps {
  text: string;
  maxLength: number;
}

export function truncate({ text, maxLength }: TruncateProps): string {
  const truncated = text.substring(0, maxLength).trim();
  const lastSpaceIndex = truncated.lastIndexOf(" ");
  return truncated.length >= text.length
    ? text
    : `${truncated.substring(0, lastSpaceIndex)}${lastSpaceIndex !== -1 ? "..." : ""}`;
}

export function getCurrentYear(): number {
  return new Date().getFullYear();
}

interface UTMparamLinkProps {
  url: string;
  source: string;
  medium: string;
  name: string;
}

export function UTMparamLink({ url, source, medium, name }: UTMparamLinkProps): string {
  const urlObj = new URL(url);
  urlObj.searchParams.set("utm_source", source);
  urlObj.searchParams.set("utm_medium", medium);
  urlObj.searchParams.set("utm_campaign", name);
  return urlObj.toString();
}

export function formatAsClassName(str: string): string {
  return str
    .trim() // trim leading/trailing whitespace
    .replace(/\s+/g, "-") // replace whitespace with dashes
    .replace(/[^\w-]/g, "") // remove any non-word or non-dash characters
    .toLowerCase();
}

export const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

export const openingHours = {
  end: "18:00",
  start: "09:00",
};

export const formatContactPointHref = (str: string): string => str.replace(/\s+/g, "");

export const manageClassNames = (...classes: (string | undefined | null)[]): string =>
  classes.filter(Boolean).join(" ");

export const formatMonetaryAmount = (amount: string): string => {
  return new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 2,
    style: "currency",
  }).format(Number(amount));
};

export const formatShape = (shape: string | null | undefined): string | undefined => {
  if (!shape) return undefined;
  return shape.toLowerCase().replace(/\s+/g, "-");
};

export const removeParagraphTags = (html: string): string => {
  return html.replace(/<\/?p>/g, "");
};

export const snakeToPascal = (str: string): string =>
  str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");

export const isObject = (value: unknown): value is Record<string, unknown> =>
  typeof value === "object" && value !== null;
